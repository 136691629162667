import { zodResolver } from '@hookform/resolvers/zod'
import { LayoutProps } from '@sourcelabbg/form/lib'
import axios from 'axios'
import { t } from 'i18next'
import { useQuery } from 'react-query'
import { FormSchema, formSchema } from '../client-schema'
import FilterSection from './filter-section'
import { SofiaAdvancedSearch, SofiaRadioInput, SofiaSearchType } from './forms/custom-inputs/custom-types'
import SofiaForm from './forms/sofia-form'
import styles from '../components/forms/custom-inputs/filters.module.css'
import { RuleSchema, SearchSchema } from '@/schema'
import { BaseSyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import chevronDown from '../assets/chevron-down.svg'
import { useRecoilState } from 'recoil'
import { showSelection } from '../state/show-selection-state'
import { resetFields } from '../state/reset-fields-state'
import { Field, FormProvider, useForm } from 'react-hook-form'
import { PartialFormSchemaWithFields } from '../pages'
import { SofiaOption } from './forms/custom-inputs/sofia-select'
import { defaultField } from './forms/custom-inputs/advanced-search'

const fieldsToCheck = [
  'advancedSearch',
  'educationIds',
  'educationLevelIds',
  'graduation',
  'entryDate',
  'herkomstCodesIds',
  'studiesIds',
  'validEmail',
  'filterEmail',
  'validPhone',
] as const
export type FieldToCheck = (typeof fieldsToCheck)[number]
export type Filters = Pick<FormSchema, FieldToCheck>
type HerkomstCodesState = {
  isCareerFair: boolean
  isJobnet: boolean
  difference: string[]
  available: SofiaOption[]
}

type DateRange = {
  from: string | null
  to: string | null
}

type HerkomstCodesMap = {
  careerfair: SofiaOption[]
  jobnet: SofiaOption[]
}

export default function SearchForm(props: {
  formValues?: PartialFormSchemaWithFields
  onSubmit: (searchData: SearchSchema, partialFormDataWithFields: PartialFormSchemaWithFields, event?: BaseSyntheticEvent) => void
  onTemplateSave?: (searchCriteria: SearchSchema) => void
  onSaveAsNewTemplate?: (searchCriteria: SearchSchema) => void
  templateName?: string
  onResetFilter?: (filterKey: keyof FormSchema) => void
}) {

  const optionJBN = { id: 'subscribedJobnet', name: t('Jobnet') }
  const optionNCB = { id: 'subscribedCareerFair', name: t('Carrièrebeurs') }
  const optionNA = { id: 'filterEmail-undefined', value: '', name: t('selection.not_applicable') }
  const filterEmailOptions = [optionJBN, optionNCB, optionNA].map((o) => ({ ...o, data: {} }))

  const saveTemplateRef = useRef<HTMLDivElement>(null)
  const { templateId } = useParams()
  const { onSubmit, onTemplateSave, onSaveAsNewTemplate, templateName } = props
  const [shouldSearch, setShouldSearch] = useRecoilState<boolean>(showSelection)
  const [fieldState, resetFieldState] = useRecoilState<boolean>(resetFields)
  const [isDropdownOpen, setDropdownState] = useState(false)
  const [isAdvancedSearchEnabled, setAdvancedSearchState] = useState(true)
  const [filterEmail, setFilterEmail] = useState<string | null>(null)
  const [herkomstCodesAvailable, setHercomstCodesAvailable] = useState<SofiaOption[] | undefined>([]);

  const [formValues, setFormValues] = useState<PartialFormSchemaWithFields>({} as PartialFormSchemaWithFields);
  useEffect(() => {
    setFormValues(props.formValues as PartialFormSchemaWithFields);
  }, [])
  const formMethods = useForm<any>({
    // TODO: what about FormSchema rather than any?
    defaultValues: formValues,
    mode: 'onChange',
  })

  const { data: studies, isLoading: studiesLoading } = useQuery(['studies'],
    async () =>
      (await axios.get(`/api/studies`)).data,
    { refetchOnWindowFocus: false })

  const { data: educations, isLoading: educationsLoading } = useQuery(['educations'],
    async () => (await axios.get(`/api/educations`)).data,
    { refetchOnWindowFocus: false })

  const { data: educationLevels, isLoading: educationLevelsLoading } = useQuery(
    ['education-levels'],
    async () => (await axios.get(`/api/education-levels`)).data,
    { refetchOnWindowFocus: false },
  )
  const { data: herkomstCodesFromDB, isLoading: herkomstCodesLoading } = useQuery<SofiaOption[]>(
    ['herkomst-codes'],
    async () => (await axios.get(`/api/herkomst-codes`)).data,
    { refetchOnWindowFocus: false },
  )

  // Check if incoming formValues have herkomstCodesIds matching either careerfair or jobnet
  // If yes then set filterEmail to the corresponding value and remove the herkomstCodesIds
  const getHerkomstCodesState = (hkcMap: HerkomstCodesMap, filterData: string | null): HerkomstCodesState => {
    const selectedHerkomstCodes = formValues.herkomstCodesIds || [];
    const careerfairCodes = hkcMap.careerfair.map(c => c.id);
    const jobnetCodes = hkcMap.jobnet.map(c => c.id);

    const isCareerFair = !!selectedHerkomstCodes.length
      && careerfairCodes.length <= selectedHerkomstCodes.length
      && careerfairCodes.every(code => selectedHerkomstCodes.includes(code));

    const isJobnet = !!selectedHerkomstCodes.length
      && jobnetCodes.length <= selectedHerkomstCodes.length
      && jobnetCodes.every(code => selectedHerkomstCodes.includes(code));

    let available: SofiaOption[] = [];

    if (isCareerFair || filterData === optionNCB.id) {
      available = hkcMap.jobnet
    }
    else if (isJobnet || filterData === optionJBN.id) {
      available = hkcMap.careerfair;
    }
    else if (!filterData) available = herkomstCodesFromDB!;

    let difference: string[] = [];

    if (isCareerFair) {
      difference = selectedHerkomstCodes.filter(x => !careerfairCodes.includes(x));
    } else if (isJobnet) {
      difference = selectedHerkomstCodes.filter(x => !jobnetCodes.includes(x));
    }

    return { isCareerFair: isCareerFair, isJobnet: isJobnet, difference, available };
  }

  const herkomstCodesMap = useMemo((): { careerfair: SofiaOption[], jobnet: SofiaOption[] } => {
    if (!herkomstCodesFromDB) return { careerfair: [], jobnet: [] }
    const normalizedNamesCodes = herkomstCodesFromDB
      .map((o: SofiaOption) => ({ ...o, name: o.name.toLowerCase() }));

    const result = {
      careerfair: normalizedNamesCodes.filter((o: SofiaOption) => o.name.includes('ncb')),
      jobnet: normalizedNamesCodes.filter((o: SofiaOption) => !o.name.includes('ncb')),
    };

    const { available } = getHerkomstCodesState(result, filterEmail);

    setHercomstCodesAvailable(available);
    return result;

  }, [herkomstCodesFromDB])

  // Filter herkomstCodes based on selected filterEmail
  const herkomstCodesFilterData = useMemo(() => {
    if (!filterEmail) return [];

    return filterEmail === optionNCB.id
      ? herkomstCodesMap.careerfair
      : herkomstCodesMap.jobnet
  }, [filterEmail])

  useEffect(() => {
    const { available } = getHerkomstCodesState(herkomstCodesMap, filterEmail);
    setHercomstCodesAvailable(available);
  }, [filterEmail])

  useEffect(() => {
    // Ensure that herkomstCodesMap is set and formValues.herkomstCodesIds is not empty
    if (!formValues.herkomstCodesIds || !formValues.herkomstCodesIds?.length) return;

    const { isCareerFair, isJobnet, difference, available } = getHerkomstCodesState(herkomstCodesMap, filterEmail);

    if (isCareerFair) {
      // If all selected codes are careerfair codes set filterEmail to careerfair
      setFormValues((prev) => ({ ...prev, filterEmail: optionNCB.id, herkomstCodesIds: [...difference] }));
      setFilterEmail(optionNCB.id);
    } else if (isJobnet) {
      // If all selected codes are jobnet codes set filterEmail to jobnet
      setFormValues((prev) => ({ ...prev, filterEmail: optionJBN.id, herkomstCodesIds: [...difference] }));
      setFilterEmail(optionJBN.id);
    }

    setHercomstCodesAvailable(available);
  }, [herkomstCodesMap, formValues.herkomstCodesIds])

  useEffect(() => {
    if (fieldState) {
      fieldsToCheck.forEach((item) => formMethods.setValue(item, undefined))
      resetFieldState(false)
    }
    if (templateId) setShouldSearch(true)
  }, [fieldState])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (event.target && saveTemplateRef.current
        && !(event.target instanceof Node && saveTemplateRef.current.contains(event.target))) {
        setDropdownState(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [saveTemplateRef])

  useEffect(() => {
    // Disable search button if all fields are empty

    let search = false;
    for (const field of fieldsToCheck) {
      const formField = formValues[field];

      // Handle advanced search fields
      if (field === 'advancedSearch')
        search = !!formValues[field]?.every((row) => row?.fieldName && row?.operator && row.value?.[0])
      // Handle DateRange fields
      else if (formField && (formField!.hasOwnProperty('from') || formField!.hasOwnProperty('to'))) {
        search = !!((formField as DateRange)?.from || (formField as DateRange)?.to)
      }
      // Handle Array<string> fields
      else if (formField && Array.isArray(formField) && formField!.hasOwnProperty('length')) {
        search = !!(formField as Array<string>)?.length;
      }
      else
        search = formValues[field] !== null && formValues[field] !== undefined

      if (search) break;
    }

    if (shouldSearch !== search)
      setShouldSearch(search);
  }, [formValues])

  const resetFilterByKey = (filterKey: FieldToCheck) => {
    if (!filterKey) return; // Prevent unnecessary execution if filterKey is null
    if (filterKey === 'filterEmail')
      setFilterEmail(null);
    setFormValues((prev) => {
      const result = { ...prev, };
      return {
        ...result, [filterKey]: filterKey === 'advancedSearch'
          ? [{ ...defaultField, id: Math.random() } as RuleSchema]
          : undefined
      }
    });

    props.formValues && formMethods.setValue(filterKey, undefined);

    if (filterKey === 'advancedSearch') {
      formMethods.setValue(filterKey, [{ ...defaultField, id: Math.random() }]);
    } else
      formMethods.setValue(filterKey, undefined);
  }

  function handleFiltersClear() {
    fieldsToCheck.forEach((item) =>
      resetFilterByKey(item)
    );
  }

  const onFilterReset = (filterKey: keyof FormSchema | null) => {
    props.onResetFilter?.(filterKey as FieldToCheck);
    resetFilterByKey(filterKey as FieldToCheck)
  }

  const convertToSchema = useCallback(
    (data: FormSchema): SearchSchema => {
      const formattedData: SearchSchema = {
        fields: data.fields,
        rules: [],
      }
      if (data.educationIds && data.educationIds.length) {
        formattedData.rules?.push({ fieldName: 'education', operator: data.educationIdsOperator || 'includes', value: data.educationIds })
      }
      if (data.educationLevelIds) {
        formattedData.rules?.push({
          fieldName: 'educationLevel',
          operator: data.educationLevelIdsOperator || 'includes',
          value: data.educationLevelIds,
        })
      }

      const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000

      if (data.graduation?.from) {
        formattedData.rules?.push({
          fieldName: 'graduationFrom',
          operator: 'after',
          value: new Date(new Date(data.graduation.from).getTime() - timezoneOffset).toISOString(),
        })
      }
      if (data.graduation?.to) {
        formattedData.rules?.push({
          fieldName: 'graduationTo',
          operator: 'before',
          value: new Date(new Date(data.graduation.to).getTime() - timezoneOffset).toISOString(),
        })
      }
      if (data.entryDate?.from) {
        formattedData.rules?.push({
          fieldName: 'entryDateFrom',
          operator: 'after',
          value: new Date(new Date(data.entryDate.from).getTime() - timezoneOffset).toISOString(),
        })
      }
      if (data.entryDate?.to) {
        formattedData.rules?.push({
          fieldName: 'entryDateTo',
          operator: 'before',
          value: new Date(new Date(data.entryDate.to).getTime() - timezoneOffset).toISOString(),
        })
      }

      if (data.filterEmail || data.filterEmail === '')
        formattedData.rules?.push({
          fieldName: 'herkomstCode',
          operator: data.herkomstCodesIdsOperator || 'includes',
          value: [...(data.herkomstCodesIds || []), ...herkomstCodesFilterData?.map((o: SofiaOption) => o.id)],
        })

      // data.filterEmail is intentionally not included in the search criteria, it overlaps with herekomstCode
      if (data.studiesIds && data.studiesIds.length) {
        formattedData.rules?.push({ fieldName: 'study', operator: data.studiesIdsOperator || 'includes', value: data.studiesIds })
      }
      if (data.validEmail || data.validEmail === '') {
        formattedData.rules?.push({ fieldName: 'validEmail', operator: 'is', value: data.validEmail })
      }
      if (data.validPhone || data.validPhone === '') {
        formattedData.rules?.push({ fieldName: 'validPhone', operator: 'is', value: data.validPhone })
      }
      data.advancedSearch?.map((filter: RuleSchema) => formattedData.rules?.push(filter))
      return formattedData
    },
    [herkomstCodesFilterData],
  )

  const searchCriteria = useMemo(
    () => {
      return convertToSchema({
        ...formValues,
        filterEmail: formValues.filterEmail ?? '',
        advancedSearch: (formValues as PartialFormSchemaWithFields).advancedSearch?.filter((row) => row?.fieldName && row?.operator && row.value?.[0]),
        fields: formValues?.fields ?? [],
      })
    },
    [formValues, herkomstCodesFilterData],
  )

  const onShowSelection = () => onSubmit(searchCriteria, formValues)

  const fields: SofiaSearchType[] = [
    {
      render: (layoutProps: LayoutProps) => {
        return (
          <>
            <div className="flex flex-col gap-4 h-[120px] w-full px-4">
              <div className="flex flex-row h-2/5 justify-between w-full">
                <h1 className="text-4xl font-bold whitespace-nowrap overflow-hidden text-ellipsis max-w-10/12">
                  {templateId ? templateName : t('selection.selection_title')}
                </h1>
                <div className="flex flex-row gap-2">
                  <button
                    className={`h-7 px-2 text-sm rounded-md bg-primary text-white ${shouldSearch ? '' : 'disabled bg-dark-grey cursor-default'}`}
                    type="button"
                    onClick={onShowSelection}
                    disabled={!shouldSearch}
                  >
                    {t('selection.show_selection')}
                  </button>
                  {!templateId && (
                    <button type="submit" name="action" value="editTemplate" className="h-7 px-2 text-sm rounded-md border-[1px] border-primary">
                      {t('selection.save_template')}
                    </button>
                  )}
                  {templateId && (
                    <div>
                      <button
                        id="dropdown-toggler"
                        className="flex flex-row items-center justify-evenly h-7 w-20 bg-primary text-white text-sm rounded-md"
                        type="button"
                        onClick={() => setDropdownState(!isDropdownOpen)}
                      >
                        {t('templates.save')}
                        <img id="dropdown-toggler" className={`w-auto`} src={chevronDown} alt="" />
                      </button>
                      <div
                        ref={saveTemplateRef}
                        className={`mt-1 absolute z-50 flex flex-col right-4 ${!isDropdownOpen && 'hidden'}`}
                        onClick={() => setDropdownState(!isDropdownOpen)}
                      >
                        <button
                          className="hover:bg-light-grey h-7 w-full px-3 text-left text-sm border-[1px] border-table-outline rounded-t-md bg-white"
                          type="button"
                          onClick={() => {
                            onTemplateSave?.(searchCriteria)
                          }}
                        >
                          {t('templates.save_changes')}
                        </button>
                        <button
                          className="hover:bg-light-grey h-7 w-full px-3 text-left text-sm border-[1px] border-t-0 border-table-outline rounded-b-md  bg-white"
                          type="button"
                          onClick={() => onSaveAsNewTemplate?.(searchCriteria)}
                        >
                          {t('templates.save_as_new')}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <FilterSection {...layoutProps} formValues={formValues} onFiltersClear={handleFiltersClear} onFilterReset={onFilterReset} />
            </div>
            <div className="flex flex-col px-4">
              <h2 className="text-lg">{t('selection.simple_search')}</h2>
              <hr className="h-[1px] my-4 bg-dark-grey" />
            </div>
          </>
        )
      },
    },

    {
      className: 'px-8 grid grid-cols-3 gap-4',
      children: [
        {
          name: 'filterEmail',
          type: 'custom',
          customControlType: 'sofia-radio-input',
          options: filterEmailOptions,
          uiOptions: {
            label: 'selection.filter_email_title',
          },
        } as SofiaRadioInput,

        {
          name: 'graduation',
          type: 'custom',
          customControlType: 'sofia-month-input',
          uiOptions: {
            label: 'selection.graduation_date_title',
          },
        },

        {
          name: 'entryDate',
          type: 'custom',
          customControlType: 'sofia-month-input',
          uiOptions: {
            label: 'selection.entry_date_title',
          },
        },

        {
          name: 'educationIds',
          type: 'custom',
          customControlType: 'sofia-select',
          options: educations,
          uiOptions: {
            label: 'selection.education_title',
          },
        },

        {
          name: 'studiesIds',
          type: 'custom',
          customControlType: 'sofia-select',
          options: studies,
          uiOptions: {
            label: 'selection.study_profile_title',
          },
        },

        {
          name: 'educationLevelIds',
          type: 'custom',
          customControlType: 'sofia-select',
          options: educationLevels,
          uiOptions: {
            label: 'selection.education_level_title',
          },
        },

        {
          name: 'herkomstCodesIds',
          type: 'custom',
          customControlType: 'sofia-select',
          options: herkomstCodesAvailable,
          uiOptions: {
            label: 'selection.herkomst_codes',
          },
        },

        {
          name: 'validEmail',
          type: 'custom',
          customControlType: 'sofia-radio-input',
          uiOptions: {
            label: 'selection.valid_email_title',
          },
        },

        {
          name: 'validPhone',
          type: 'custom',
          customControlType: 'sofia-radio-input',
          uiOptions: {
            label: 'selection.valid_phone_title',
          },
        },
        { render: () => <div>&nbsp;</div> },
      ],
    },
    {
      render: (layoutProps: LayoutProps) => (
        <div className="flex flex-row gap-4 px-8">
          <input
            className={`${styles.advancedToggle} bg-gray-200 checked:bg-primary relative inline-flex flex-shrink-0 w-11 h-6 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out`}
            id="advanced-seatch-toggle"
            type="checkbox"
            onChange={() => {
              setAdvancedSearchState(!isAdvancedSearchEnabled)
              layoutProps.setValue(
                'advanced-search',
                layoutProps.formValues?.advancedSearch?.splice(0, layoutProps.formValues?.advancedSearch?.length),
              )
            }}
            role="switch"
            defaultChecked={isAdvancedSearchEnabled}
          />
          <h2>{t('selection.advanced_search')}</h2>
        </div>
      ),
    },
    {
      name: 'advancedSearch',
      type: 'custom',
      customControlType: 'sofia-advanced-search',
      onShowSelection,
      uiOptions: {
        visible() {
          return isAdvancedSearchEnabled
        },
      },
    } as SofiaAdvancedSearch,
  ]

  const onFieldChange = (name: string, change: { new: string[] | string }, oldFormValues: FormSchema) => {
    if (!oldFormValues) setShouldSearch(false);

    const currentFormValues = { ...oldFormValues, [name]: change.new };
    setFormValues(currentFormValues);
    formMethods.setValue(name, change.new);

    // Extract relevant search criteria
    const { fields: _fields, graduation, advancedSearch, ...restSearchCriteria } = currentFormValues;

    const hasAdvancedSearch = advancedSearch?.some((row) => row?.fieldName && row?.operator && row.value?.[0]);

    const hasGraduation = graduation && !!Object.values(graduation).find((value) => value != undefined);
    const hasValue = !!Object.keys(restSearchCriteria).find((key: string) => {
      if (key.includes('Operator')) {
        return false;
      }

      const value = restSearchCriteria[key as keyof typeof restSearchCriteria];
      return value != undefined && (Array.isArray(value) ? value.length > 0 : true);
    });

    setShouldSearch(!!(hasAdvancedSearch || hasValue || hasGraduation));

    // Update filterEmail options based on selected value, this triggers herecomstCodes to update
    if (name === 'filterEmail') {
      //if (!filterEmail) return;
      setFilterEmail(change.new as string);
      const { available } = getHerkomstCodesState(herkomstCodesMap, change.new as string);
      setHercomstCodesAvailable(available);
    }

  }

  if (studiesLoading || educationLevelsLoading || educationsLoading || herkomstCodesLoading || !formValues) return <div>{t('selection.loading')}</div>

  return (
    <FormProvider {...formMethods}>
      <SofiaForm
        fields={fields}
        values={formValues}
        resolver={zodResolver(formSchema)}
        onSubmit={(data, e?: BaseSyntheticEvent) => {
          onSubmit(
            convertToSchema({
              ...data,
              advancedSearch: data.advancedSearch?.filter((row) => row?.fieldName && row?.operator && row.value?.[0]),
              fields: formValues.fields,
            }),
            formValues,
            e,
          )
        }}
        onFieldChange={onFieldChange}
      />
    </FormProvider>
  )
}