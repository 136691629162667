import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import chevronDown from '../assets/chevron-down.svg'
import Filters, { defaultColumns } from './forms/custom-inputs/filters'
import dayjs from 'dayjs'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { resetFields } from '../state/reset-fields-state'
import { Fields, columnsState } from '../state/columns-state'
import { SearchSchema } from '@/schema'

export default function Result({ searchCriteria, resetFilters }: { searchCriteria: SearchSchema; resetFilters?: () => void }) {
  const { t } = useTranslation()
  const { templateId } = useParams()
  const [columns, setColumns] = useState<Fields[]>(searchCriteria ? searchCriteria.fields : defaultColumns)
  const resetFieldState = useSetRecoilState<boolean>(resetFields)
  const setColumnsState = useSetRecoilState(columnsState)

  const requestBody = useMemo(() => ({ ...searchCriteria, fields: columns }), [searchCriteria, columns]);

  const { data: results, isLoading } = useQuery(['search-results', requestBody], async () => {
    const response = await axios.post(
      `/api/preview`,
      { ...requestBody, limit: 10 },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
    return response.data
  }, { refetchOnWindowFocus: false })

  const [isDropdownOpen, setDropdownState] = useState(false)
  useEffect(() => {
    window.addEventListener('click', (event) => {
      // @ts-ignore
      if (event.target?.id !== 'dropdown-toggler') {
        setDropdownState(false)
      }
    })
  }, [])

  useEffect(() => {
    setColumnsState(columns)
  }, [columns])

  return (
    <>
      <div className="flex flex-row justify-between items-center mt-14 px-8">
        <div className="flex flex-row text-4xl font-bold">
          <h1>{t('selection.selection_title')} (</h1>
          {results ? (
            <h1>{results.count}</h1>
          ) : (
            <div className="h-50px">
              <div id="preloader" className="count">
                <div id="loader" className="count"></div>
              </div>
            </div>
          )}
          <h1>)</h1>
        </div>
        <div className="flex flex-row gap-2">
          <div>
            <button
              id="dropdown-toggler"
              className="flex flex-row items-center justify-evenly h-7 w-28 bg-primary text-white text-xs rounded-md"
              onClick={() => setDropdownState(!isDropdownOpen)}
            >
              {t('selection.download')}
              <img id="dropdown-toggler" className={`w-auto`} src={chevronDown} alt="" />
            </button>
            <div className={`mt-1 absolute z-50 ${!isDropdownOpen && 'hidden'}`}>
              <form action="/api/export" method="post" className="flex flex-col w-28">
                <input type="hidden" name="data" value={JSON.stringify(requestBody)} />
                <button
                  className="hover:bg-light-grey h-7 w-28 pl-3 text-left text-sm border-[1px] border-table-outline rounded-t-md bg-white"
                  type="submit"
                  name="fileType"
                  value="csv"
                >
                  CSV
                </button>
                <button
                  className="hover:bg-light-grey h-7 w-28 pl-3 text-left text-sm border-[1px] border-t-0 border-table-outline rounded-b-md  bg-white"
                  type="submit"
                  name="fileType"
                  value="xlsx"
                >
                  Excel
                </button>
              </form>
            </div>
          </div>
          {!templateId && (
            <button
              type="button"
              onClick={() => {
                resetFilters && resetFilters()
                resetFieldState(true)
              }}
              className="h-7 border-2 px-2 border-primary rounded-md text-xs"
            >
              {t('selection.delete_selection')}
            </button>
          )}
        </div>
      </div>
      <div className="px-8 my-7">
        <Filters value={columns} onChange={(values) => setColumns(values)} />
      </div>
      {isLoading && !results ? (
        <div className="h-1000px mt-14 px-8">
          <div id="preloader">
            <div id="loader" className="table"></div>
          </div>
        </div>
      ) : (
        <div className="px-8 my-7">
          <p className="text-xs mb-2 mt-10"> ({t('selection.preview') + results.data.length})</p>
          <div className="overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full rounded-full divide-y divide-table-outline">
              <thead className="bg-light-grey">
                <tr>
                  {columns.map((filter: string) => (
                    <th scope="col" className="py-3.5 px-3 text-left text-base font-normal text-dark-grey sm:pl-6 lg:pl-8" key={filter}>
                      {t(`fields.${filter}`)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-table-outline bg-white">
                {results.data.map((row: any, i: number) => {
                  return (
                    <tr key={i}>
                      {columns.map((field) => {
                        return (
                          <td key={field} className="text-dark-grey whitespace-nowrap py-4 pl-4 pr-3 text-base sm:pl-6 lg:pl-8">
                            {field !== 'birthDate' && field !== 'entryDate' && row[field]?.toString()}
                            {field === 'birthDate' && dayjs(row[field]).locale('se').format('YYYY-MM-DD')}
                            {field === 'entryDate' && dayjs(row[field]).locale('se').format('YYYY-MM-DD')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}
