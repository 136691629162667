import { RuleSchema, SearchSchema } from '../../server/src/search-schema'
import { FormSchema } from './client-schema'

export function convertSearchSchemaToFormSchema(template: SearchSchema): FormSchema {
  // @ts-ignore
  const fields = template.rules?.reduce(
    (acc: FormSchema, rule: RuleSchema) => {
      if (rule?.fieldName === 'education') {
        acc.educationIds = rule?.value
        acc.educationIdsOperator = rule?.operator
      } else if (rule?.fieldName === 'educationLevel') {
        acc.educationLevelIds = rule?.value
        acc.educationLevelIdsOperator = rule?.operator
      } else if (rule?.fieldName === 'graduationFrom') {
        acc.graduation = { from: rule?.value }
      } else if (rule?.fieldName === 'graduationTo') {
        acc.graduation = { ...acc.graduation, to: rule?.value }
      } else if (rule?.fieldName === 'entryDateFrom') {
        acc.entryDate = { from: rule?.value }
      } else if (rule?.fieldName === 'entryDateTo') {
        acc.entryDate = { ...acc.graduation, to: rule?.value }
      } else if (rule?.fieldName === 'herkomstCode') {
        acc.herkomstCodesIds = rule?.value
        acc.herkomstCodesIdsOperator = rule?.operator
      } else if (rule?.fieldName === 'study') {
        acc.studiesIds = rule?.value
        acc.studiesIdsOperator = rule?.operator
      } else if (rule?.fieldName === 'validEmail') {
        acc.validEmail = rule?.value
      } else if (rule?.fieldName === 'filterEmail') {
        acc.filterEmail = rule?.value
      } else if (rule?.fieldName === 'validPhone') {
        acc.validPhone = rule?.value
      } else {
        if (!acc.advancedSearch) acc.advancedSearch = []
        acc.advancedSearch.push(rule)
      }
      return acc
    },
    { fields: template.fields },
  )
  // @ts-ignore
  return { fields: template.fields, ...fields }
}
