import { LayoutProps } from '@sourcelabbg/form/lib'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FieldToCheck, Filters } from './search-form'
import { defaultField } from './forms/custom-inputs/advanced-search'
import { FormSchema } from "../client-schema";

export const isSelected = (boxName: FieldToCheck, formValues: Partial<Filters>) => {
  switch (boxName) {
    case 'graduation':
      return formValues?.['graduation']?.from || formValues?.['graduation']?.to
    case 'entryDate':
      return formValues?.['entryDate']?.from || formValues?.['entryDate']?.to
    case 'educationIds':
      return !!formValues?.['educationIds']?.length
    case 'studiesIds':
      return !!formValues?.['studiesIds']?.length
    case 'educationLevelIds':
      return !!formValues?.['educationLevelIds']?.length
    case 'herkomstCodesIds':
      return !!formValues?.['herkomstCodesIds']?.length
    case 'validEmail':
      return !!formValues?.['validEmail'] || formValues?.['validEmail'] === ''
    case 'filterEmail':
      return !!formValues?.['filterEmail']?.length || formValues?.['filterEmail'] === ''
    case 'validPhone':
      return !!formValues?.['validPhone'] || formValues?.['validPhone'] === ''
    case 'advancedSearch':
      return !!formValues?.['advancedSearch']?.some((row) => row?.fieldName && row?.operator && row.value?.[0])
  }
}

interface FilterSectionProps extends LayoutProps {
  formValues: Partial<Filters>
  templateId?: number
  onFiltersClear: () => void
  onFilterReset: (filterKey: keyof FormSchema | null) => void
}

export default function FilterSection({ formValues, onFiltersClear, onFilterReset }: FilterSectionProps) {
  const { t } = useTranslation()
  const { templateId } = useParams()
  const navigate = useNavigate()

  const [filterValues, setFilterValues] = React.useState<Partial<Filters>>({});

  useEffect(() => {
    setFilterValues(formValues);
  }, [formValues]);

  const titles = [
    { key: 'graduation', label: t('selection.graduation_date_title') },
    { key: 'entryDate', label: t('selection.entry_date_title') },
    { key: 'educationIds', label: t('selection.education_title') },
    { key: 'studiesIds', label: t('selection.study_profile_title') },
    { key: 'educationLevelIds', label: t('selection.education_level_title') },
    { key: 'herkomstCodesIds', label: 'Herkomst' },
    { key: 'validEmail', label: t('selection.valid_email_title') },
    { key: 'filterEmail', label: t('selection.filter_email_title') },
    { key: 'validPhone', label: t('selection.valid_phone_title') },
    { key: 'advancedSearch', label: t('selection.advanced_filters') },
  ] as const
  const showFilterButton =
    !!filterValues?.['graduation']?.from ||
    !!filterValues?.['entryDate']?.from ||
    !!filterValues?.['entryDate']?.to ||
    !!filterValues?.['graduation']?.to ||
    !!filterValues?.['educationIds']?.length ||
    !!filterValues?.['studiesIds']?.length ||
    !!filterValues?.['educationLevelIds']?.length ||
    !!filterValues?.['herkomstCodesIds'] ||
    !!filterValues?.['validEmail'] || filterValues?.['validEmail'] === '' ||
    !!filterValues?.['filterEmail'] || filterValues?.['filterEmail'] === '' ||
    !!filterValues?.['validPhone'] || filterValues?.['validPhone'] === '' ||
    !!filterValues?.['advancedSearch']?.some((row) => row?.fieldName && row?.operator && row.value?.[0])

  const resetFilterByKey = (key: FieldToCheck) => {
    setFilterValues((prev) => {
      return { ...prev, [key]: key === 'advancedSearch' ? [{ ...defaultField, id: Math.random() }] : undefined }
    });
  }

  const resetFilter = (item: (typeof titles)[number]) => {
    resetFilterByKey(item.key);
    onFilterReset(item.key);
  }

  const resetFilters = () => {
    for (const title of titles) {
      resetFilterByKey(title.key);
    }
    onFiltersClear();
  }

  return (
    <div className="flex justify-between flex-row">
      <span className="flex flex-row w-10/12 gap-2">
        {titles.map((item) => {
          return (
            <React.Fragment key={item.key}>
              {isSelected(item.key, filterValues) && (
                <span className="inline-flex items-center rounded bg-light-grey py-0.5 pl-2 pr-0.5 text-xs font-medium text-dark-grey">
                  <strong>{item.label}</strong>
                  <button
                    id="removeFilter"
                    type="button"
                    className="removeFilterButton ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-xl text-dark-grey hover:bg-dark-grey hover:text-white"
                    onClick={() => resetFilter(item)}
                  >
                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                      <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                  </button>
                </span>
              )}
            </React.Fragment>
          )
        })}
      </span>
      <span>
        {showFilterButton &&
          (!templateId ? (
            <button
              className="h-7 px-3 w-auto text-sm rounded-md border-[1px] border-dark-grey"
              type="button"
              onClick={resetFilters}
            >
              {t('selection.clear_filters')}
            </button>
          ) : (
            <>
              <button
                className="h-7 px-3 w-auto text-sm rounded-md border-[1px] border-dark-grey"
                type="button"
                onClick={() => {
                  navigate('/templates')
                }}
              >
                {t('templates.cancel')}
              </button>
            </>
          ))}
      </span>
    </div>
  )
}
