import { FormProps } from '@sourcelabbg/form/lib'
import { useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SofiaRadioInput } from './custom-types'

export default function RadioCard({ field, formProps }: { field: SofiaRadioInput; formProps: FormProps }) {
  const { t } = useTranslation()
  const options = field.options?.map((o) => ({ ...o, title: o.name, value: o.value != undefined ? o.value : o.id })) ?? [
    { id: `${field.name}-yes`, title: t('selection.yes'), value: 'yes' },
    { id: `${field.name}-no`, title: t('selection.no'), value: 'no' },
    { id: `${field.name}-undefined`, title: t('selection.not_applicable'), value: '' },
  ]

  return (
    <Controller
      control={formProps.control}
      name={`${field.name}`}
      render={({ field: { onChange, ref, value, name } }) => {
        const val = formProps.getValues(field.name) ?? value
        const component = useMemo(() => {
          return (
            <div className={`p-4 border-[1px] min-h-220px ${value ? 'border-primary' : 'border-light-grey'} rounded-lg`}>
              <div className="flex flex-row ">
                <h3 className="mb-4 font-bold">{`${field.uiOptions?.label && t(field.uiOptions?.label)}`}</h3>
              </div>
              {options.map((option) => (
                <span key={option.id} className="flex items-center">
                  <input
                    id={option.id}
                    key={option.id}
                    name={name}
                    type="radio"
                    value={option.value}
                    ref={ref}
                    onChange={onChange}
                    checked={option?.value?.toString() === val?.toString()}
                    className={`h-4 w-4 border-radio-outline text-primary focus:ring-transparent ${
                      option.id === name + '-undefined' ? 'text-dark-grey' : 'text-primary'
                    }`}
                  />
                  <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                    {option.title}
                  </label>
                </span>
              ))}
            </div>
          )
        }, [val])
        return component
      }}
    />
  )
}
